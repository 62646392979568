import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'

type MetaProps = JSX.IntrinsicElements['meta']

export interface SEOProps {
    description?: string
    lang?: string
    meta?: MetaProps[]
    title: string
}

const SEO: React.FC<SEOProps> = props => {
    const { title } = props
    const lang = props.lang || 'en'
    const meta = props.meta || []
    const description = props.description || ''

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    )
    const metaDescription = description || site.siteMetadata.description
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={([
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                // {
                //     name: `twitter:card`,
                //     content: `summary`,
                // },
                // {
                //     name: `twitter:creator`,
                //     content: site.siteMetadata.author,
                // },
                // {
                //     name: `twitter:title`,
                //     content: title,
                // },
                // {
                //     name: `twitter:description`,
                //     content: metaDescription,
                // },
            ] as MetaProps[]).concat(meta)}
        >
            {/* <link rel="stylesheet" href="https://rsms.me/inter/inter.css" /> */}
        </Helmet>
    )
}

export default SEO
