// @ts-ignore
import { Link, useIntl } from 'gatsby-plugin-intl'
// import { useLocation } from '@reach/router'
import * as React from 'react'
import { useState } from 'react'

import { ClickOutsideContent } from 'components/click-outside-content'
import { LanguageSwitcher } from 'components/language-switcher'

import { Logo, LogoProps } from 'components/logo'

import fullLogo from 'images/logo_full.svg'
import oneLetterLogo from 'images/logo.svg'

export interface NavBarProps extends LogoProps {
    shadow?: boolean
}

export const NavBar: React.FC<NavBarProps> = props => {
    // we use useLocation because non page components don't receive the location in props
    // const { pathname } = useLocation()
    // console.log(pathname)
    const { alt, logo } = props
    const [activeMenuItem, setActiveMenuItem] = useState('')
    const [showMobileNavbar, setShowMobileNavbar] = useState(false)
    const intl = useIntl()

    const shadow = props.shadow ? 'shadow-sm' : null

    const onClose = () => {
        setActiveMenuItem('')
    }

    const onCloseMobileNavbar = () => {
        setShowMobileNavbar(false)
    }

    const onClickButtonMobileNavbar = () => {
        setShowMobileNavbar(true)
    }

    const onMenuItemClick = (id: string) => {
        setActiveMenuItem(id)
    }

    return (
        <div className="z-0 relative px-6 pt-4 md:max-w-3xl md:mx-auto lg:mx-0 lg:max-w-none lg:pt-0 lg:pb-16">
            <div className={`relative z-10 ${shadow}`}>
                <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
                    <Logo alt={alt} logo={fullLogo} />
                    <TogglePhoneNavbar onClick={onClickButtonMobileNavbar} />

                    {/* Navbar container */}
                    <div className="hidden md:flex-1 md:flex md:items-center md:justify-between md:space-x-12">
                        {/* Navbar */}
                        <nav className="flex space-x-10">
                            {/* <ButtonNavbarDropdown
                                id="why-schedio"
                                onClick={onMenuItemClick}
                            >
                                {intl.formatMessage({
                                    id: 'header.whySchedio.label',
                                })}
                            </ButtonNavbarDropdown>
                            <ButtonNavbarDropdown
                                id="solutions"
                                onClick={onMenuItemClick}
                            >
                                {intl.formatMessage({
                                    id: 'header.solutions.label',
                                })}
                            </ButtonNavbarDropdown>
                            <NavbarButton
                                to={`/${intl.formatMessage({
                                    id: 'header.pricing.slug',
                                })}`}
                            >
                                {intl.formatMessage({
                                    id: 'header.pricing.label',
                                })}
                            </NavbarButton>
                            <ButtonNavbarDropdown
                                id="resources"
                                onClick={onMenuItemClick}
                            >
                                {intl.formatMessage({
                                    id: 'header.resources.label',
                                })}
                            </ButtonNavbarDropdown> */}
                        </nav>
                        {/* Sign in buttons */}
                        <div className="flex items-center space-x-8">
                            <LanguageSwitcher />
                            <a
                                href="https://my.schedio.app"
                                target="_blank"
                                className="text-sm font-semibold text-white focus:outline-none focus:underline"
                            >
                                Login →
                            </a>
                            {/* <span className="inline-flex rounded-md shadow-sm">
                                <a
                                    href="#"
                                    className="inline-flex whitespace-no-wrap
                                            items-center justify-center px-4
                                            py-2 border border-transparent
                                            text-base leading-6 font-medium rounded-md
                                            text-white bg-schedio-blue-500 hover:bg-schedio-blue-400
                                            focus:outline-none focus:border-schedio-blue-700
                                            focus:shadow-outline-indigo active:bg-schedio-blue-700
                                            transition ease-in-out duration-150"
                                >
                                    Sign up
                                </a>
                            </span> */}
                        </div>
                    </div>
                </div>
            </div>

            {activeMenuItem === 'why-schedio' && (
                <ClickOutsideContent onClose={onClose}>
                    <SolutionMenu />
                </ClickOutsideContent>
            )}

            {activeMenuItem === 'solutions' && (
                <ClickOutsideContent onClose={onClose}>
                    <SolutionMenu />
                </ClickOutsideContent>
            )}

            {activeMenuItem === 'resources' && (
                <ClickOutsideContent onClose={onClose}>
                    <SolutionMenu />
                </ClickOutsideContent>
            )}

            {/*             
                Mobile menu, show/hide based on mobile menu state.

                Entering: "duration-200 ease-out"
                From: "opacity-0 scale-95"
                To: "opacity-100 scale-100"
                Leaving: "duration-100 ease-in"
                From: "opacity-100 scale-100"
                To: "opacity-0 scale-95"
            */}
            {showMobileNavbar && (
                <MobileNavbar
                    alt={alt}
                    logo={oneLetterLogo}
                    onClose={onCloseMobileNavbar}
                />
            )}
        </div>
    )
}

interface MobileNavbarProps extends LogoProps {
    onClose: () => void
}

const MobileNavbar: React.FC<MobileNavbarProps> = props => {
    const { alt, logo, onClose } = props

    return (
        <div className="absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg">
                <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
                    <div className="pt-5 pb-6 px-5 space-y-6 sm:space-y-8 sm:pb-8">
                        <div className="flex items-center justify-between">
                            <Logo alt={alt} logo={logo} />
                            <div className="-mr-2">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center
                                        p-2 rounded-md text-gray-400 hover:text-gray-500
                                        hover:bg-gray-100 focus:outline-none
                                        focus:bg-gray-100 focus:text-gray-500
                                        transition duration-150 ease-in-out"
                                    onClick={onClose}
                                >
                                    <svg
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div>
                            <nav className="space-y-8">
                                <div className="grid gap-7 sm:grid-cols-2 sm:row-gap-8 sm:col-gap-4">
                                    <a
                                        href="#"
                                        className="-m-3 space-x-4 flex items-center p-3 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                    >
                                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                            <svg
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                                                />
                                            </svg>
                                        </div>
                                        <div className="text-base leading-6 font-medium text-gray-900">
                                            Analytics
                                        </div>
                                    </a>
                                    <a
                                        href="#"
                                        className="-m-3 space-x-4 flex items-center p-3 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                    >
                                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                            <svg
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                                                />
                                            </svg>
                                        </div>
                                        <div className="text-base leading-6 font-medium text-gray-900">
                                            Engagement
                                        </div>
                                    </a>
                                    <a
                                        href="#"
                                        className="-m-3 space-x-4 flex items-center p-3 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                    >
                                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                            <svg
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                                />
                                            </svg>
                                        </div>
                                        <div className="text-base leading-6 font-medium text-gray-900">
                                            Security
                                        </div>
                                    </a>
                                    <a
                                        href="#"
                                        className="-m-3 space-x-4 flex items-center p-3 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                    >
                                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                            <svg
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                                />
                                            </svg>
                                        </div>
                                        <div className="text-base leading-6 font-medium text-gray-900">
                                            Integrations
                                        </div>
                                    </a>
                                </div>
                                <div className="text-base leading-6">
                                    <a
                                        href="#"
                                        className="font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                                    >
                                        View all products &rarr;
                                    </a>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="py-6 px-5 space-y-6">
                        <div className="grid grid-cols-2 gap-4">
                            <a
                                href="#"
                                className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
                            >
                                Pricing
                            </a>
                            <a
                                href="#"
                                className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
                            >
                                Docs
                            </a>
                            <a
                                href="#"
                                className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
                            >
                                Company
                            </a>
                            <a
                                href="#"
                                className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-600 transition ease-in-out duration-150"
                            >
                                Resources
                            </a>
                            <a
                                href="#"
                                className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-600 transition ease-in-out duration-150"
                            >
                                Blog
                            </a>
                            <a
                                href="#"
                                className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-600 transition ease-in-out duration-150"
                            >
                                Contact Sales
                            </a>
                        </div>
                        <div className="space-y-6">
                            <span className="w-full flex rounded-md shadow-sm">
                                <a
                                    href="#"
                                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                                >
                                    Sign up
                                </a>
                            </span>
                            <p className="text-center text-base leading-6 font-medium text-gray-500">
                                Existing customer?{' '}
                                <a
                                    href="#"
                                    className="text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                                >
                                    Sign in
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const NavbarButton: React.FC<{ to: string }> = props => {
    return (
        <Link
            to={props.to}
            className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
        >
            {props.children}
        </Link>
    )
}

interface ButtonNavbarDropdownProps {
    id: string
    onClick?: (id: string) => void
}

// Item active: "text-gray-900", Item inactive: "text-gray-500"
const ButtonNavbarDropdown: React.FC<ButtonNavbarDropdownProps> = props => {
    const onClick = () => {
        props?.onClick(props.id)
    }

    return (
        <button
            type="button"
            className="group text-gray-500 whitespace-no-wrap inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
            onClick={onClick}
        >
            <span>{props.children}</span>
            <ArrowDownIcon />
        </button>
    )
}

interface TogglePhoneNavbarProps {
    onClick: () => void
}

const TogglePhoneNavbar: React.FC<TogglePhoneNavbarProps> = props => {
    return (
        <div className="-mr-2 -my-2 md:hidden">
            <button
                type="button"
                className="inline-flex items-center justify-center p-2
                        rounded-md text-gray-400 hover:text-gray-500
                        hover:bg-gray-100 focus:outline-none
                        focus:bg-gray-100 focus:text-gray-500
                        transition duration-150 ease-in-out"
                onClick={props.onClick}
            >
                <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                    />
                </svg>
            </button>
        </div>
    )
}

{
    /* <!-- Item active: "text-gray-600", Item inactive: "text-gray-400" --> */
}
const ArrowDownIcon: React.FC = () => {
    return (
        <svg
            className="text-gray-400 h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
            />
        </svg>
    )
}

interface DropdownProps {
    overlayClassName?: string
    overlay: React.ReactNode
}

const Dropdown: React.FC<DropdownProps> = props => {
    const { overlayClassName, overlay } = props
    const [visible, setVisible] = useState(false)

    const onClick = () => {
        setVisible(true)
    }

    const onClose = () => {
        setVisible(false)
    }

    return (
        <div className="relative">
            <div onClick={onClick}>{props.children}</div>
            {visible && (
                <ClickOutsideContent onClose={onClose}>
                    {overlay}
                </ClickOutsideContent>
            )}
        </div>
    )
}

const SolutionMenu = () => {
    return (
        <div className="absolute inset-x-0 transform shadow-lg">
            <div className="bg-white">
                <div className="max-w-7xl mx-auto grid row-gap-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                    <a
                        href="#"
                        className="-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                        <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                            <div className="flex-shrink-0">
                                <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                    <svg
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                                <div className="space-y-1">
                                    <p className="text-base leading-6 font-medium text-gray-900">
                                        Analytics
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                        Get a better understanding of where your
                                        traffic is coming from.
                                    </p>
                                </div>
                                <p className="text-sm leading-5 font-medium text-indigo-600">
                                    Learn more &rarr;
                                </p>
                            </div>
                        </div>
                    </a>
                    <a
                        href="#"
                        className="-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                        <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                            <div className="flex-shrink-0">
                                <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                    <svg
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                                <div className="space-y-1">
                                    <p className="text-base leading-6 font-medium text-gray-900">
                                        Engagement
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                        Speak directly to your customers in a
                                        more meaningful way.
                                    </p>
                                </div>
                                <p className="text-sm leading-5 font-medium text-indigo-600">
                                    Learn more &rarr;
                                </p>
                            </div>
                        </div>
                    </a>
                    <a
                        href="#"
                        className="-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                        <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                            <div className="flex-shrink-0">
                                <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                    <svg
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                                <div className="space-y-1">
                                    <p className="text-base leading-6 font-medium text-gray-900">
                                        Security
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                        Your customer data will be safe and
                                        secure.
                                    </p>
                                </div>
                                <p className="text-sm leading-5 font-medium text-indigo-600">
                                    Learn more &rarr;
                                </p>
                            </div>
                        </div>
                    </a>
                    <a
                        href="#"
                        className="-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                        <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                            <div className="flex-shrink-0">
                                <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                    <svg
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                                <div className="space-y-1">
                                    <p className="text-base leading-6 font-medium text-gray-900">
                                        Integrations
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                        Connect with third-party tools that
                                        you're already using.
                                    </p>
                                </div>
                                <p className="text-sm leading-5 font-medium text-indigo-600">
                                    Learn more &rarr;
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div className="bg-gray-50">
                <div className="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                    <div className="flow-root">
                        <a
                            href="#"
                            className="-m-3 p-3 space-x-3 flex items-center rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                        >
                            <svg
                                className="flex-shrink-0 h-6 w-6 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            <span>Watch Demo</span>
                        </a>
                    </div>
                    <div className="flow-root">
                        <a
                            href="#"
                            className="-m-3 p-3 space-x-3 flex items-center rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                        >
                            <svg
                                className="flex-shrink-0 h-6 w-6 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            <span>View all products</span>
                        </a>
                    </div>
                    <div className="flow-root">
                        <a
                            href="#"
                            className="-m-3 p-3 space-x-3 flex items-center rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                        >
                            <svg
                                className="flex-shrink-0 h-6 w-6 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                />
                            </svg>
                            <span>Contact Sales</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
