import { Link } from 'gatsby-plugin-intl'
import * as React from 'react'

export interface LogoProps {
    alt?: string
    logo?: string | React.ReactNode
    to?: string
}

export const Logo: React.FC<LogoProps> = props => {
    const { alt, logo } = props

    return (
        <div className="logo">
            <Link to={props.to || '/'} className="flex">
                {typeof logo === 'string' ? (
                    <img className="h-6 w-auto" src={logo} alt={alt} />
                ) : (
                    logo
                )}
            </Link>
        </div>
    )
}
