import { changeLocale, IntlContextConsumer, useIntl } from 'gatsby-plugin-intl'
import * as React from 'react'
import { useState } from 'react'

import { ClickOutsideContent } from 'components/click-outside-content'

export const LanguageSwitcher: React.FC = () => {
    const [visible, setVisible] = useState(false)
    const intl = useIntl()

    const languageName = {
        en: 'English',
        es: 'Spanish',
        fr: 'Français',
    }

    const onShow = () => {
        setVisible(true)
    }

    const onClose = () => {
        setVisible(false)
    }

    return (
        <div className="relative inline-block text-left leading-none">
            <button
                aria-label={intl.formatMessage({
                    id: 'header.localeBtnAriaLabel',
                })}
                onClick={onShow}
                type="button"
                className="inline-flex justify-center w-full 
                                text-sm leading-5 border-none
                                text-white hover:text-gray-300
                                focus:outline-none rounded-full focus:border-schedio-blue-500
                                focus:shadow-outline-blue
                                active:bg-gray-50 active:text-gray-800
                                transition ease-in-out duration-150"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
            >
                <GlobeIcon />
            </button>

            {/* <!--
                Dropdown panel, show/hide based on dropdown state.

                Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            --> */}
            {visible && (
                <ClickOutsideContent onClose={onClose}>
                    <div className="origin-top absolute right-0 mt-2 rounded-md shadow-lg">
                        <div className="rounded-md bg-white shadow-xs">
                            <div
                                className="py-1"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="options-menu"
                            >
                                <IntlContextConsumer>
                                    {({ languages, language: currentLocale }) =>
                                        languages.map(language => {
                                            const text =
                                                currentLocale === language
                                                    ? 'text-schedio-blue-500'
                                                    : 'text-gray-700'
                                            return (
                                                <a
                                                    key={language}
                                                    className={`block px-4 py-2 text-sm leading-5
                                                         hover:bg-gray-100
                                                        hover:text-gray-900 focus:outline-none
                                                        focus:bg-gray-100 focus:text-gray-900 ${text}`}
                                                    onClick={() =>
                                                        changeLocale(language)
                                                    }
                                                    role="menuitem"
                                                    // style={{
                                                    //     color:
                                                    //         currentLocale === language
                                                    //             ? `blue`
                                                    //             : `green`,
                                                    //     margin: 10,
                                                    //     textDecoration: `underline`,
                                                    //     cursor: `pointer`,
                                                    // }}
                                                >
                                                    {languageName[language]}
                                                </a>
                                            )
                                        })
                                    }
                                </IntlContextConsumer>
                            </div>
                        </div>
                    </div>
                </ClickOutsideContent>
            )}
        </div>
    )
}

const GlobeIcon: React.FC = () => {
    return (
        <div className="w-7 h-7">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                />
            </svg>
        </div>
    )
}
