import * as React from 'react'

import { Header } from './header/Header'

import * as styles from './Layout.module.less'

export interface LayoutProps {
    layoutClassName?: string
    contentClassName?: string
    content: React.ReactNode
    header?: React.ReactNode
    headerClassName?: string
    seo: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = props => {
    const header =
        props.header !== null || props.header !== undefined ? (
            props.header
        ) : (
            <Header />
        )

    return (
        <React.Fragment>
            {props.seo}
            <div className={`${styles.layout} ${props.layoutClassName}`}>
                <div className={`${styles.header} ${props.headerClassName}`}>
                    <div className={`relative`}>{header}</div>
                </div>
                <div className={`${styles.content} ${props.contentClassName}`}>
                    {props.content}
                </div>
            </div>
        </React.Fragment>
    )
}
