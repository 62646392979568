import * as React from 'react'

import { NavBar } from 'components/navbar'

export const Header: React.FC = props => {
    return (
        <div className="relative">
            <NavBar
                alt="logo workflow"
                logo={
                    'https://tailwindui.com/img/logos/workflow-mark-on-white.svg'
                }
            />
        </div>
    )
}

const HeaderNavLink: React.FC<{
    className?: string
    name: React.ReactNode
    to: string
}> = props => {
    return (
        <a
            href={props.to}
            className={`text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150 ${props.className}`}
        >
            {props.name}
        </a>
    )
}
